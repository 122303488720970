<template>
  <nav class="nav flex-column" role="navigation">
    <h4 class="mb-2">Components</h4>
    <router-link class="nav-link" to="/" exact>Home</router-link>
    <router-link class="nav-link" to="buttons">Buttons</router-link>
    <router-link class="nav-link" to="cards">Cards</router-link>
    <router-link class="nav-link" to="collapse">Collapse</router-link>
    <router-link class="nav-link" to="colours">Colours</router-link>
    <router-link class="nav-link" to="dialogs">Dialogs</router-link>
    <router-link class="nav-link" to="feedback">Feedback</router-link>
    <router-link class="nav-link" to="forms">Forms</router-link>
    <router-link class="nav-link" to="help">Help</router-link>
    <router-link class="nav-link" to="icons">Icons</router-link>
    <router-link class="nav-link" to="layout">Layout</router-link>
    <router-link class="nav-link" to="navigation">Navigation</router-link>
    <router-link class="nav-link" to="page-headers">Page Headers</router-link>
    <router-link class="nav-link" to="step-wizard">Step Wizard</router-link>
    <router-link class="nav-link" to="tables">Tables</router-link>
    <router-link class="nav-link" to="tabs">Tabs</router-link>
    <router-link class="nav-link" to="typography">Typography</router-link>
    <router-link class="nav-link" to="utilities">Utilities</router-link>
  </nav>
</template>

<script>
export default {
  name: "Sidebar"
};
</script>
