<template>
  <div class="page-header">
    <div class="container-fluid">
      <breadcrumbs></breadcrumbs>
      <div class="d-lg-flex align-items-end justify-content-between">
        <div>
          <h1 class="page-title">{{ title }}</h1>
          <h3 class="page-title-sub">
            <router-link to="/pages/applications">{{ subTitle }}</router-link>
          </h3>
          <ul class="list-inline mb-1">
            <li class="list-inline-item"><span class="text-muted">Package:</span> <strong>Land Title</strong></li>
            <li class="list-inline-item">
              <span class="text-muted">Status:</span>
              <strong>
                Unsubmitted
                <a
                  href="#"
                  data-toggle="popover"
                  data-placement="top"
                  data-title="Help Text"
                  data-content="This is an example of some help text"
                  data-original-title=""
                  title=""
                  aria-describedby="popover96323"
                >
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                </a>
              </strong>
            </li>
          </ul>
        </div>
        <ul class="nav mt-3-md mt-lg-1 d-print-none">
          <li class="nav-item">
            <button
              class="btn btn-md btn-link pl-0 pl-lg-2 pr-2 dropdown-toggle"
              id="managePackage"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Manage Package
            </button>
            <div class="dropdown-menu" aria-labelledby="managePackage">
              <a class="dropdown-item" href="javascript:void(0)">Download Package</a>
              <a class="dropdown-item" href="javascript:void(0)">Delete Package</a>
            </div>
          </li>
          <li class="nav-item mr-1"><a class="btn btn-md btn-secondary px-2" href="javascript:void(0)">E-sign</a></li>
          <li class="nav-item">
            <a class="btn btn-md btn-secondary mt-1 mt-sm-0 px-2" href="javascript:void(0)">
              Prepare Submission
              <svg class="icon icon-arrow_cta"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },

  data() {
    return {};
  },
};
</script>
