<template>
  <div class="drawer help-panel fade" id="help-panel">
    <div class="drawer-content help-panel-content right">
      <div class="help-panel-toggle d-print-none">
        <button
          class="btn btn-narrow btn-success"
          type="button"
          data-toggle="drawer"
          data-target="#help-panel"
          aria-controls="help-panel"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          <strong class="d-none d-md-inline-block">Help</strong>
        </button>
      </div>
      <button class="close" data-dismiss="drawer" aria-label="Close">
        <span>&times;</span>
      </button>
      <h3 class="d-flex align-items-center">
        <svg class="icon icon-help icon-lg text-tertiary  mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg> Help Center
      </h3>
      <hr />
      <h4>Help topics related to this page</h4>
      <ul class="list-unstyled nav mb-1">
        <li>
          <a href="javascript:void(0)">Watch the Search Services tutorials</a>
        </li>
        <li>
          <a href="javascript:void(0)">How do I search titles using a parcel identifier?</a>
        </li>
        <li>
          <a href="javascript:void(0)">How do I set title search options?</a>
        </li>
        <li>
          <a href="javascript:void(0)">Can I search for a title using a civic address?</a>
        </li>
      </ul>
      <div class="input-group mb-3">
        <input type="text" class="form-control form-control-sm" />
        <div class="input-group-append">
          <button class="btn btn-sm btn-tertiary" type="button">
            <small>Search Help</small>
          </button>
        </div>
      </div>
      <hr />
      <a href="javascript:void(0)">
        <svg class="icon icon-mail"><use xlink:href="/icons/symbol-defs.svg#icon-mail"></use></svg> Contact Us
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
