<template>
  <div class="drop" v-bind:class="{ droppable: droppable }">
    <label class="drop-label text-center">
      <strong>Drag PDF files to upload</strong><br>or
      <button class="btn btn-secondary d-block mt-2">
        Choose PDF Files
      </button>
    </label>
    <input type="file" ref="fileform" class="dropzone" accept=""
        :multiple="true"
        id="uploader"
        @change="handleUpload">
  </div>
</template>

<script>
import Vue from 'vue';
// import vDragDrop from 'v-drag-drop';

// Vue.use(vDragDrop);

export default {
    name: 'FileUpload',

  data() {
    return {
      droppable: false
    }
  },

  mounted () {
    const vm = this

    vm.pdf = document.getElementById('uploader')

    this.$refs.fileform.addEventListener("dragenter", function (e) {
      vm.droppable = true
    });

    this.$refs.fileform.addEventListener("dragleave", function (e) {
      vm.droppable = false
    });
  },

  methods: {

    dragEnter () {
      this.droppable = true
    },

    dragLeave () {
      this.droppable = false
    },

    handleUpload (ev) {

    },
  }
}
</script>
