<template>
  <main>
    <div class="page-header" v-if="header == true">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="row">
          <div class="col-sm-9">
            <h1 class="page-title">{{ title }}<br /></h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <aside class="col-md-2 d-none d-lg-block" v-if="sidebar == true">
          <sidebar></sidebar>
        </aside>
        <main class="col">
          <slot></slot>
        </main>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    title: String,
    sidebar: {
      type: Boolean,
      default: true
    },
    header: {
      type: Boolean,
      default: true
    }
  }
};
</script>
