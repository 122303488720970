<template>
<div class="date-picker" tabindex="0">
    <div class="ngb-dp-header bg-light">
        <!---->
        <div class="d-flex">
            <div class="ngb-dp-arrow">
                <button aria-label="Previous month" class="btn btn-link ngb-dp-arrow-btn" title="Previous month" type="button"><span class="ngb-dp-navigation-chevron"></span></button>
            </div>
            <!---->
            <div class="ngb-dp-navigation-select">
                <select aria-label="Select month" class="custom-select" title="Select month">
                    <!---->
                    <option value="1" aria-label="January">Jan</option>
                    <option value="2" aria-label="February">Feb</option>
                    <option value="3" aria-label="March">Mar</option>
                    <option value="4" aria-label="April">Apr</option>
                    <option value="5" aria-label="May">May</option>
                    <option value="6" aria-label="June">Jun</option>
                    <option value="7" aria-label="July">Jul</option>
                    <option value="8" aria-label="August">Aug</option>
                    <option value="9" aria-label="September">Sep</option>
                    <option value="10" aria-label="October">Oct</option>
                    <option value="11" aria-label="November">Nov</option>
                    <option value="12" aria-label="December">Dec</option>
                </select>
                <select aria-label="Select year" class="custom-select" title="Select year">
                    <!---->
                    <option value="2009">2009</option>
                    <option value="2010">2010</option>
                    <option value="2011">2011</option>
                    <option value="2012">2012</option>
                    <option value="2013">2013</option>
                    <option value="2014">2014</option>
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                </select>
            </div>
            <!---->
            <div class="ngb-dp-arrow right">
                <button aria-label="Next month" class="btn btn-link ngb-dp-arrow-btn" title="Next month" type="button"><span class="ngb-dp-navigation-chevron"></span></button>
            </div>
        </div>
    </div>
    <div class="ngb-dp-months">
        <!---->
        <div class="ngb-dp-month">
            <!---->
            <div class="ngb-dp-month-name bg-light"> June 2019 </div>
            <div role="grid">
                <!---->
                <div class="ngb-dp-week ngb-dp-weekdays bg-light">
                    <!---->
                    <!---->
                    <div class="ngb-dp-weekday small"> Mo </div>
                    <div class="ngb-dp-weekday small"> Tu </div>
                    <div class="ngb-dp-weekday small"> We </div>
                    <div class="ngb-dp-weekday small"> Th </div>
                    <div class="ngb-dp-weekday small"> Fr </div>
                    <div class="ngb-dp-weekday small"> Sa </div>
                    <div class="ngb-dp-weekday small"> Su </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Monday, May 27, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Tuesday, May 28, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Wednesday, May 29, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Thursday, May 30, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Friday, May 31, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Saturday, June 1, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 1 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Sunday, June 2, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 2 </span>
                        <!---->
                    </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Monday, June 3, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 3 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Tuesday, June 4, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 4 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day ngb-dp-today" role="gridcell" tabindex="-1" aria-label="Wednesday, June 5, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 5 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Thursday, June 6, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 6 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Friday, June 7, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 7 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Saturday, June 8, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 8 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Sunday, June 9, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 9 </span>
                        <!---->
                    </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Monday, June 10, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 10 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Tuesday, June 11, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 11 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Wednesday, June 12, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 12 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Thursday, June 13, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 13 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Friday, June 14, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 14 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Saturday, June 15, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 15 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Sunday, June 16, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 16 </span>
                        <!---->
                    </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Monday, June 17, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 17 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Tuesday, June 18, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 18 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Wednesday, June 19, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 19 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Thursday, June 20, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 20 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Friday, June 21, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 21 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Saturday, June 22, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 22 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Sunday, June 23, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 23 </span>
                        <!---->
                    </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Monday, June 24, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 24 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Tuesday, June 25, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 25 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Wednesday, June 26, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 26 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Thursday, June 27, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 27 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Friday, June 28, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 28 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Saturday, June 29, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 29 </span>
                        <!---->
                    </div>
                    <div class="ngb-dp-day" role="gridcell" tabindex="-1" aria-label="Sunday, June 30, 2019">
                        <!---->
                        <!----><span _ngcontent-c6="" class="custom-day"> 30 </span>
                        <!---->
                    </div>
                </div>
                <!---->
                <!---->
                <div class="ngb-dp-week" role="row">
                    <!---->
                    <!---->
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Monday, July 1, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Tuesday, July 2, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Wednesday, July 3, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Thursday, July 4, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Friday, July 5, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Saturday, July 6, 2019">
                        <!---->
                    </div>
                    <div class="ngb-dp-day hidden" role="gridcell" tabindex="-1" aria-label="Sunday, July 7, 2019">
                        <!---->
                    </div>
                </div>
                <!---->
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'DatePicker'
}
</script>

<style>
  .date-picker {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 0px;
    will-change: transform;
    transform: translate(0px, 44px);
    background-color: #fff;
    z-index: 1000;
  }

  .date-picker select {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25;
    height: inherit;
    width: 50%;
    display: inline-block;
  }

  .ngb-dp-month {
    pointer-events: none;
  }
  .ngb-dp-header {
    border-bottom: 0px;
    border-radius: .25rem 0.25rem 0rem 0rem;
    padding-top: 0.25rem;
  }
  .date-picker-month {
    pointer-events: auto;
  }
  .ngb-dp-month-name {
    font-size: larger;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
  }
   .ngb-dp-month + .ngb-dp-month > .date-picker-month > .ngb-dp-week {
    padding-left: 1rem;
  }
   .ngb-dp-month + .ngb-dp-month > .ngb-dp-month-name {
    padding-left: 1rem;
  }
   .ngb-dp-month:last-child .ngb-dp-week {
    padding-right: .25rem;
  }
   .ngb-dp-month:first-child .ngb-dp-week {
    padding-left: .25rem;
  }
   .ngb-dp-month > .date-picker-month > .ngb-dp-week:last-child {
    padding-bottom: .25rem;
  }
  .ngb-dp-months {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .date-picker-nav {
    height: 2rem;
    line-height: 1.85rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ngb-dp-navigation-chevron {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    margin-left: 0.25em;
    margin-right: 0.15em;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
  }
  .right .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 0.15em;
    margin-right: 0.25em;
  }
  .ngb-dp-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex-basis: auto;
    flex-grow: 1;
    padding-right: 0px;
    padding-left: 0px;
    margin: 0px;
    width: 2rem;
    height: 2rem;
  }
  .ngb-dp-arrow.right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .ngb-dp-arrow-btn {
    padding: 0rem 0.25rem;
    margin: 0rem 0.5rem;
    border: none;
    background-color: transparent;
    z-index: 1;
  }
  .ngb-dp-arrow-btn:focus {
    outline-style: auto;
    outline-width: 1px;
  }
  .ngb-dp-month-name {
    font-size: larger;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
  }
  .ngb-dp-navigation-select {
    display: flex;
    -webkit-box-flex: 1 1 9rem;
    -ms-flex:  1 1 9rem;
    flex-grow: 1;
    flex-basis: 9rem;
  }


  .ngb-dp-weekday, .ngb-dp-week-number {
    line-height: 2rem;
  }
  .ngb-dp-weekday {
    color: #5bc0de;
  }
  .ngb-dp-week {
      border-radius: 0.25rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
  .ngb-dp-weekdays {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0rem;
  }
  .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    width: 2rem;
    height: 2rem;
  }
  .ngb-dp-day {
    cursor: pointer;
  }
  .ngb-dp-day > div {
    text-align: center;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 0.25rem;
    background: transparent;
  }

  .ngb-dp-day .outside {
    opacity: 0.5;
  }

  .ngb-dp-day.disabled, .ngb-dp-day.hidden {
    cursor: default;
  }

  .ngb-dp-weekday {
  }

</style>